<template>
  <v-row>
    <v-col cols="12" v-if="loaderMain">
      <v-boilerplate type="table-heading, divider, list-item-two-line" />
      <v-skeleton-loader class="mt-5" transition="fade-transition" type="table" />
    </v-col>
    <v-col cols="12" v-else>
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">
            <v-row align="center" justify="start" no-gutters>
              <router-link class="subtitle-1 routerLink font-weight-medium" :to="{name: 'ContactListCreate', params: $route.params}">Personas</router-link>
              <v-icon class="mx-1 pt-1" color="secondary">mdi-chevron-right</v-icon>
              <span class="subtitle-1 font-weight-medium d-inline-block text-truncate" :style="$vuetify.breakpoint.width < 1200 ? 'max-width: 300px;' : 'max-width: 500px;'">{{instance.name}}</span>
            </v-row>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-btn class="ml-2" @click="dialogContact=true" outlined color="secondary" retain-focus-on-click>Editar</v-btn>
              <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
                <template v-slot:activator="{on, attrs}">
                  <v-btn class="ml-2" v-bind="attrs" v-on="on" min-width="34" max-width="34" outlined color="secondary" :ripple="false" retain-focus-on-click><v-icon color="secondary">mdi-dots-horizontal</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-5 py-1" @click="destroyContact" :ripple="false">
                    <v-list-item-title class="subtitle-2 red--text font-weight-medium">Eliminar persona</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-row>
            <v-col cols="6">
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-title>Nombre</v-list-item-title>
                  <v-list-item-subtitle>{{instance.name}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Email</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-tooltip color="bgSearch" right transition="scale-transition">
                      <template v-slot:activator="{on}">
                        <v-btn class="font-weight-regular" @click="toClipboard(instance.email)" text color="secondary" v-on="on" :ripple="false">{{instance.email}}</v-btn>
                      </template>
                      <span class="d-block px-3 py-2">Copiar</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Teléfono</v-list-item-title>
                  <v-list-item-subtitle v-if="instance.phone">{{instance.phone}}</v-list-item-subtitle>
                  <v-list-item-subtitle class="font-italic text--disabled" v-else>Sin número de teléfono</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>ID</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-tooltip color="bgSearch" right transition="scale-transition">
                      <template v-slot:activator="{on}">
                        <v-btn class="font-weight-regular" @click="toClipboard(instance.id)" text color="secondary" v-on="on" :ripple="false">{{instance.id}}</v-btn>
                      </template>
                      <span class="d-block px-3 py-2">Copiar</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Creado el</v-list-item-title>
                  <v-list-item-subtitle>{{instance.created | dateTime}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Actualizado el</v-list-item-title>
                  <v-list-item-subtitle>{{instance.updated | dateTime}}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-title>Notificaciones activas</v-list-item-title>
                  <v-list-item-subtitle v-if="instance.documents || instance.collections">
                    <v-chip class="mr-1" v-if="instance.documents" color="primary" text-color="white" small>Emisión</v-chip>
                    <v-chip class="mr-1" v-if="instance.collections" color="primary" text-color="white" small>Cobranza</v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="font-italic text--disabled" v-else>Sin notificaciones activas</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- customer detail -->
      <v-card class="px-0 my-5">
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Cliente</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text>
          <v-row align="center" justify="space-between" v-if="instance.customer && customersDetail.id">
            <v-col cols="5">
              <v-btn :to="{name: 'CustomerRetrieveUpdate', params: {id: customersDetail.id, ...$route.params}}" text color="primary">{{customersDetail.name}}<v-icon right>mdi-arrow-right</v-icon></v-btn>
              <span class="d-block">{{customersDetail.address}}, {{customersDetail.country.name}}</span>
            </v-col>
            <v-col v-if="orgunitsList.length">
              <span class="ml-1 body-2 text--secondary font-weight-medium">Unidad organizativa <v-chip class="ml-1 mt-n1" color="secondary" x-small>Opcional</v-chip></span>
              <v-select v-model="editable.orgunit" @change="updateContact" clearable label="Seleccionar unidad organizativa" single-line :items="orgunitsList" item-value="id" item-text="name" hide-details dense height="30" outlined>
                <template v-slot:item="data">
                  <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                </template>
              </v-select>
            </v-col>
            <v-col class="text-right">
              <v-btn @click="destroyCustomer" outlined color="red darken-2" dark retain-focus-on-click>Desvincular cliente</v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="pb-6 pt-4 text-center">
              <v-icon class="d-block" color="secondary" size="70">mdi-domain</v-icon>
              <span class="d-block subtitle-1 my-2">Esta persona aún no está vinculada a un cliente</span>
              <v-btn @click="dialogCustomer=true" outlined color="secondary" retain-focus-on-click>Vincular cliente</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- end customer detail -->

    </v-col>

    <!-- dialog update contact -->
    <v-dialog v-model="dialogContact" v-if="!$store.state.customers.retrieveLoader" width="700" scrollable persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="55">
            <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Editar persona</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-1">
                <v-btn class="ml-3" @click="dialogContact=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="v-list-form py-6 transparent">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.editable.name.$model" :error="$v.editable.name.$error" outlined required single-line dense placeholder="Escribe un nombre" maxlength="64" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Email</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="10">
                  <v-text-field v-model="$v.editable.email.$model" :error="$v.editable.email.$error" outlined required single-line dense placeholder="Escribe un email" maxlength="60" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Teléfono</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="6">
                  <v-text-field v-model="$v.editable.phone.$model" :error="$v.editable.phone.$error" outlined required single-line dense placeholder="Escribe un teléfono" maxlength="12" v-mask="`${editable.phone} # #### ####`" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="mt-4">
              <v-list-item-title>
                <span class="text--secondary font-weight-medium">¿Qué notificaciones debe recibir esta persona?</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Emisión</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-switch class="mt-n4" v-model="editable.documents" style="position:absolute" />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Cobranza</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-switch class="mt-n4" v-model="editable.collections" style="position:absolute" />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="dialogContact=false, $v.editable.$reset()" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="updateContact" :loading="$store.state.customers.createLoader" color="primary">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog update contact -->

    <!-- dialog customer -->
    <v-dialog v-model="dialogCustomer" width="600" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Seleccionar un cliente</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="pr-1">
              <v-btn icon retain-focus-on-click :ripple="false" small @click="dialogCustomer=false"><v-icon small>mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" class="text-left">
              <v-col class="py-2">
                <v-text-field v-model="customerQuery" outlined required single-line dense placeholder="Escribe para buscar un cliente..." hide-details />
              </v-col>
              <v-divider />
              <v-skeleton-loader v-if="$store.state.customers.retrieveLoader" transition="fade-transition" type="list-item" />
              <template v-else>
                <template v-if="customersList.length">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="d in customersList" :key="d.id" @click="setCustomer(d)" style="cursor: pointer">
                          <td>
                            <span class="body-1 text--secondary font-weight-medium">{{d.name}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                <v-col v-else class="text-center py-5">
                  <v-icon color="secondary" size="70">mdi-close-circle</v-icon>
                  <span class="d-block subtitle-1">No existen clientes</span>
                </v-col>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog customer -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'

export default {
  mixins: [
    GenericViewMixin
  ],
  inject: ['theme'],
  components: {
    VBoilerplate: {
      functional: true,
      render (h, { data, props, children }) {
        return h('v-skeleton-loader', {
          ...data,
          props: {
            boilerplate: true,
            elevation: 2,
            ...props
          }
        }, children)
      }
    }
  },
  data: () => ({
    customerQuery: '',
    editable: {
      collections: false,
      email: '',
      documents: false,
      name: '',
      phone: '',
      orgunit: null
    },
    dialogCustomer: false,
    dialogDestroyCustomer: false,
    dialogContact: false,
    loaderMain: false
  }),
  computed: mapState({
    instance: state => state.customers.contactsDetail,
    customersList: state => state.customers.customersList,
    customersDetail: state => state.customers.customersDetail,
    orgunitsList: state => state.customers.orgunitsList
  }),
  watch: {
    customerQuery () {
      this.debounceMethod(this.getCustomers)
    }
  },
  created () {
    this.loaderMain = true

    this.$store.dispatch('customers/RETRIEVE', {
      resource: 'contacts',
      id: this.$route.params.id
    })
    .then(() => {
      if (this.instance.customer) {
        this.getCustomer()
      }

      Object.keys(this.editable).forEach(k => {
        this.editable[k] = this.instance[k]
      })
    })
    .finally(() => {
      this.loaderMain = false
    })
  },
  methods: {
    updateContact () {
      this.$v.editable.$touch()

      if (this.$v.editable.$invalid) {
        return false
      }

      if (this.editable.orgunit === undefined) {
        this.editable.orgunit = null
      }

      this.$store.dispatch('customers/UPDATE', {
        resource: 'contacts',
        payload: this.editable,
        id: this.$route.params.id
      })
      .then(() => {
        this.dialogContact = false
        this.$dialog.message.info('La persona ha sido actualizado')
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.detail)
      })
    },
    getCustomer () {
      this.$store.dispatch('customers/RETRIEVE', {
        resource: 'customers',
        id: this.instance.customer
      })
      .then(() => {
        this.getOrgunits()
      })
    },
    getCustomers () {
      if ([1, 2].includes(this.customerQuery.length)) {
        return false
      }
      const query = {
        page_size: 10
      }
      if (this.customerQuery.length >= 3) {
        query.name__icontains = this.customerQuery.toLowerCase()
      }
      this.$store.dispatch('customers/LIST', {
        resource: 'customers',
        query: query
      })
    },
    getOrgunits () {
      this.$store.dispatch('customers/LIST', {
        resource: 'orgunits',
        query: {
          customer: this.instance.customer
        }
      })
    },
    destroyContact () {
      this.$dialog.warning({
        title: 'Eliminar persona',
        text: `
          <span class="d-block my-4">¿Estás seguro de eliminar esta persona?</span>
        `,
        actions: [
          {
            color: 'red',
            text: 'Continuar',
            handle: () => {
              this.$store.dispatch('customers/DESTROY', {
                resource: 'contacts',
                id: this.$route.params.id
              })
              .then(() => {
                this.$router.push({ name: 'ContactListCreate', params: this.$route.params })
              })
              .catch((error) => {
                this.$dialog.message.error(this.parseErrors(error.response.data))
              })
            }
          }
        ]
      })
    },
    setCustomer (customer) {
      this.$dialog.warning({
        title: 'Vincular cliente',
        text: `
          <span class="d-block my-4">¿Estás seguro de asociar cliente a esta persona?</span>
        `,
        actions: [
          {
            color: 'red',
            text: 'Continuar',
            handle: () => {
              this.$store.dispatch('customers/UPDATE', {
                resource: 'contacts',
                payload: {
                  customer: customer.id
                },
                id: this.$route.params.id
              })
              .then(() => {
                this.getCustomer()
                this.dialogCustomer = false
                this.$dialog.message.info('El cliente ha sido asociado a la persona')
              })
              .catch((error) => {
                this.$dialog.message.error(this.parseErrors(error.response.data))
              })
            }
          }
        ]
      })
    },
    destroyCustomer () {
      this.$dialog.warning({
        title: 'Eliminar asociación',
        text: `
          <span class="d-block my-4">¿Estás seguro de eliminar la asociación de esta persona con el cliente?</span>
        `,
        actions: [
          {
            color: 'red',
            text: 'Continuar',
            handle: () => {
             this.$store.dispatch('customers/UPDATE', {
                resource: 'contacts',
                payload: {
                  customer: null
                },
                id: this.$route.params.id
              })
              .then(() => {
                this.$dialog.message.info('El cliente ha sido eliminado')
              })
              .catch(() => {
                this.$dialog.message.error('El cliente no se ha podido eliminar')
              })
            }
          }
        ]
      })
    }
  },
  validations: {
    editable: {
      name: {
        required,
        maxLength: maxLength(64)
      },
      email: {
        required,
        email,
        maxLength: maxLength(254)
      },
      phone: {
        maxLength: maxLength(12)
      }
    }
  }
}
</script>